<template>
  <FocusTrap>
    <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h4 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>&nbsp; Digital Payment List</h4>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="voucher-table"
             class="table table-bordered table-columned"
             data-search="false"
             data-pagination="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-sortable="true" data-width="75" >S.No</th>
          <th data-width="100" data-field="doc_no" data-sortable="true" data-align="center">Doc No</th>
          <th data-width="107" data-field="doc_date" data-sortable="true" data-formatter="dateFormatter" >Date</th>
          <th data-field="details" data-formatter="ledgerFormatter" data-sortable="true">Descrption</th>
          <th data-field="remarks" data-sortable="true">Narration</th>
          <th data-field="amount" data-width="200" data-align="right" data-sortable="true" data-formatter="indianFormat">Amount</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <vmodal name="voucher_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="475" @before-open="beforeOpen" @before-close="beforeClose" >
      <VoucherForm v-bind:myvoucher="voucher" v-on:payment_updated="closeModal" ></VoucherForm>
    </vmodal>

  </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import VoucherForm from '@/views/xpos/vaultpayment/VaultPaymentForm.vue'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'DigitalPaymentView',
  components: {
    VoucherForm
  },
  store,
  data () {
    return {
      mytable: {},
      voucher: JSON.parse('{"id":0,"type":0,"status":0,"finyear":0,"iscredit":false,"vchtype":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"status":0,"type":0,"name":"","alias":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","parent":0,"nature":0,"level":0,"is_profit":false,"is_subledger":false,"is_net_bal":false},"add":{"name":"","street":"","city":"","pin":"","state_cd":0,"email":"","tel":"","mobile":""},"open_bal":0,"gstin":""},"amount":0,"remarks":"","ibr":0,"locked":false,"list":[]}'),
      branches: [],
      selected_ibr: 1,
      voucher_id: 0,
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

    self.$data.mytable = $('#voucher-table');


    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#voucher-table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyVoucher(id);
        } else if ($(e.target).text() === 'Cancel') {
          self.cancelVoucher(id);
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

      $('#voucher-table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();

    });


    self.loadData();
  },
  methods: {
    showModal () {
      this.$modal.show('voucher_window');
    },
    beforeOpen () {

    },
    beforeClose () {

    },
    closeModal () {
      this.$modal.hide('voucher_window')
    },
    loadData () {
      let self = this;
      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('');

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      let ibr = parseInt(self.$store.state.xpos.branch.id);

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');


      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/accounts/svoucher/series/111/finyear/${self.$store.state.xpos.finyear}/?ibr=${ibr}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          if (_.isArray(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data)
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading')
        $(self.$data.mytable).unblock()
      })
    },
    modifyVoucher (id) {
      let self = this
      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.voucher = {}
      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/accounts/svoucher/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          self.$data.voucher = resp.data
          self.$data.voucher.doc_date = resp.data.doc_date.slice(0, 10)

          self.$data.voucher.details.forEach(function (det, i) {
            if (det.idx == 0) {
              self.$data.voucher.ledger = det.ledger
              self.$data.voucher.details.splice(i, 1);
            }
          })

          self.$modal.show('voucher_window');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    cancelVoucher (id) {
      let self = this

      self.$data.voucher.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.voucher)
      }

      swal({
        title: 'What is the reason for cancel this voucher?',
        input: 'text',
        inputPlaceholder: 'Enter Remarks here',
        showCancelButton: true,
        inputClass: 'form-control',
        inputValidator: function (value) {
          return !value && 'You need to write something!'
        }
      }).then(function (result) {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/voucher/${id}/?remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('voucher_window');
            if (resp.accepted) {
              self.loadData();
              swal({
                title: 'Canceled!',
                text: 'Your request has been processed',
                type: 'success'
              })
            } else {
              swal({ title: 'Oops', text: resp.msg, type: 'info' })
            }
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
  }
}

</script>

<style scoped>

</style>
